<template>
  <div class="space_manage">
    <div class="space_value">
      <el-row style="padding: 0 20px;box-sizing: border-box">
        <el-col :span="24">
          <el-table :data="spaceValue" border style="width: 100%" :header-cell-class-name="cellname" :cell-class-name="cellClassName">
            <el-table-column label="空间统计" width="130" align="center"></el-table-column>
            <el-table-column prop="total_count" label="目前已发布招租空间" align="center"></el-table-column>
            <el-table-column prop="measure" label="累计面积" align="center"></el-table-column>
            <el-table-column prop="workstation" label="累计工位" align="center"></el-table-column>
            <el-table-column prop="rent_count" label="已租" align="center"></el-table-column>
            <el-table-column prop="not_rent_count" label="正在招租" align="center"></el-table-column>
            <el-table-column width="150" align="center"></el-table-column>
          </el-table>
        </el-col>
        <div class="space_value_down">
          <div style="display: flex;align-items: center;justify-content: center;cursor: pointer;">
            <img style="width: 16px;height: 16px;" src="~@/assets/images/ipdown.png" alt="">
            <download-excel
                class = "export-excel-wrapper"
                :data="downExcel"
                name = "众创载体统计报表.xls">
              <div class="btn-index-blue-single">
                下载表格
              </div>
            </download-excel>
          </div>
        </div>
      </el-row>
    </div>
    <el-container>
      <el-header class="space_manage_top display_flex">
        <div>
          <el-input
            placeholder="请输入空间名称"
            style="width:360px;"
            prefix-icon="el-icon-search"
            v-model="searchValue"
             @keyup.enter.native="spaceList"
          ></el-input>
          <el-button type="primary" @click="spaceList" style="margin-left:20px;" size="medium" class="searchBtn">搜索</el-button>
        </div>
        <div>
          <el-button type="primary" @click="setSpace" size="small" class="smallBtn">发布空间</el-button>
        </div>
      </el-header>
      <el-main>
        <el-table :data="tableData" style="width: 100%" align='center'>
          <el-table-column  align="center" label="图片">
            <template slot-scope="scope">
              <img :src="scope.row.image?scope.row.image:''" width="100" height='60' alt="">
            </template>
          </el-table-column>
          <el-table-column align="center" show-overflow-tooltip label="地理位置">
            <template slot-scope="scope">
              <span>{{scope.row.province}}{{scope.row.city}}{{scope.row.district}}{{scope.row.town}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" align="center" show-overflow-tooltip  label="空间名称"></el-table-column>
          <el-table-column label="类型" align="center" prop='park_type'>
            <template slot-scope="scope">
              <span v-for="(item,index) in scope.row.park_type" style='margin-right:6px;' :key="index">{{item}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="city" align="center" label="单位面积"></el-table-column>
          <el-table-column prop="mobile" align="center" label="常用手机"></el-table-column>
          <el-table-column prop="tel" align="center" label="电话" ></el-table-column>
          <el-table-column prop="rent_state" align="center" label="状态" ></el-table-column>
          <el-table-column prop="pay_type" align="center" label="付费方式" ></el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="180px">
            <template slot-scope="scope">
              <el-button type="primary" @click="toEdit(scope)" size="mini"  class="editTab">编辑</el-button>
              <el-button type='danger'   @click="toDelete(scope)" size="mini" class="delTab">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
         <PageComponents  :total="meta.total"
              styles="text-align: center;margin-top: 20px;"
              :page="page"
              v-show="meta.last_page>1"
              @currentPageChange="currentPageChange" />
      </el-main>
    </el-container>
  </div>
</template>
<script>
// import { error, warning, success } from "@/utils/notification";
import PageComponents from '../../../components/PageComponents.vue';
import { error, success } from "@/utils/notification";

import Vue from 'vue'
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
export default {
  data() {
    return {
      spaceValue: [],
      downExcel: [
        {
          '目前已发布招租空间': '',
          '累计面积': '',
          '累计工位': '',
          '已租': '',
          '正在招租': ''
        }
      ],
      tableData:[],
      searchValue:'',
      page:1,
      meta:{},
      organizationId:1 // 机构id
    };
  },
  components: {
    PageComponents
  },
  created(){
    if(sessionStorage.organizationId){
      this.organizationId = sessionStorage.organizationId
    }
    this.spaceValueFun()
    this.getSpaceList()
  },
  watch:{
    page(){
       this.getSpaceList()
    }
  },
  methods:{
    cellClassName({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 6) {
        return 'cell-class-name'
      }
    },
    cellname(row) {
      if (row.columnIndex === 0 || row.columnIndex === 6) {
        return 'first-column-header'
      } else {
        return 'second-column-header'
      }
    },
    // 空间统计
    spaceValueFun(){
      this.$http.get('/api/organs/'+ this.organizationId +'/statistics/count').then(res=>{
        this.spaceValue = []
        this.spaceValue.push(res.data.data)
        this.downExcel[0]['目前已发布招租空间'] = this.spaceValue[0].total_count
        this.downExcel[0]['累计面积'] = this.spaceValue[0].measure
        this.downExcel[0]['累计工位'] = this.spaceValue[0].workstation
        this.downExcel[0]['已租'] = this.spaceValue[0].rent_count
        this.downExcel[0]['正在招租'] = this.spaceValue[0].not_rent_count
      })
    },
    spaceList(){ // 搜索调用的方法
      this.page = 1
      this.getSpaceList()
    },
    currentPageChange(val){ // 页码改变的时候
      this.page = val
    },
    toEdit(scope){ // 编辑
      let id = scope.row.id
      this.$router.push({
        name:'AddSpace',
         query:{
           id:id
         }
      })
    },
    toDelete(scope){ // 删除
      let id = scope.row.id
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http
        .delete(this.URL.adminUrl.delSpace+id,{params:{organ_id:this.organizationId}})
        .then(()=>{
            this.getSpaceList()
            this.spaceValueFun()
            success('删除成功')
        })
        .catch(err=>{
          error(err.response.data.message);
        })
      })
      
    },
    setSpace(){ // 发布空间
       this.$router.push({
         name:'AddSpace'
       })
    },
    getSpaceList(){ // 获取列表数据
       let name = this.searchValue
       let page = this.page,id = this.organizationId
       this.$http
       .get(this.URL.adminUrl.spaceList+id+'/spaces',{params:{
         page:page,
         name:name
       }})
       .then(res=>{
         this.meta = res.data.data.meta
         this.tableData = res.data.data.spaces
       })
       .catch(err=>{
            error(err.response.data.message);
       })
    }
  }
};
</script>
<style lang="less" scoped>

</style>
<style lang="less">
  .space_value{
    position: relative;
    .first-column-header{
      border-bottom: none !important;
      padding: 0 !important;
      padding-top: 40px !important;
      background-color: #fff !important;
    }
    .second-column-header{
      background-color: #f2f2f2 !important;
    }
    .cell-class-name{
      padding: 0 !important;
      padding-bottom: 20px !important;
      cursor: pointer;
    }
    .btn-index-blue-single {
        color: #528cfe !important;
    }
    .space_value_down{
      position: absolute;
      top: 0;
      right: 20px;
      height: 105px;
      width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
